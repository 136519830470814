<template>
  <div class="page">
    <!-- <div class="banner"></div>  -->
    <div class="main-width-container">
        <div class="text-center">
          <div class="article-title">辅导教师操作指南</div>
          <div class="cylct" @click="isShowPop = true"></div>
        </div>
        <div class="text-content">
          <p> 教师通过活动网站注册，并根据学校或学生所填信息与学生小组账号进行关联，即可管理所辅导的学生小组，了解小组学生网上调查数据和报告的提交情况。辅导教师还可提交实践报告（11月底前），参评全国优秀教师实践报告。 </p>
          <video src="https://cysccresource.cyscc.org/video/file/2fudaojiaoshi.mp4" poster="../../assets/js-poster.jpg" controls="controls" width="100%" height="100%" style="margin-bottom: 24px;"></video>
          <div class="text-title">
            <span>1.注册登录</span>
          </div>
          <p> 为学生小组提供辅导的教师，需在活动平台注册。若去年已经注册过，无需重新注册，可直接用此前注册过的账户登录。 </p>
          <div class="sub-text-title">
            <span>1.1注册</span>
          </div>
          <p> 访问“青少年科学调查体验活动”官网( http://www.scienceday.org.cn/ )，点击右侧导航【注册】，将进入科创筑梦账号注册页面。注册时可使用邮箱或手机作为登录用户名。 </p>
          <img src="https://cyscc.org/dctyhd/img/hd1.cb084b48.png" alt="">
          <div class="sub-text-title">
            <span>1.2登录</span>
          </div>
          <p> 若已经有账号，点击官网右侧导航【登录】，进入科创筑梦登录页面，按提示输入【用户名】和【密码】，点击登录按钮，即可登录系统。 </p>
          <img src="https://cyscc.org/dctyhd/img/hd2.13629dbe.png" alt="">
          <div class="text-title">
            <span>2.选择身份、完善信息</span>
          </div>
          <p> 注册后首次登录，将进入身份选择界面。教师用户请选择【辅导老师】身份。注意：身份请勿选择错误。 </p>
          <img src="https://cyscc.org/dctyhd/img/hd3.80b7884f.png" alt="">
          <p> 在个人信息填写页面，请按要求完善相关信息，点击保存后，即完成教师账号的创建。 </p>
          <p> 注意，职业信息内的【单位】填写后将不可更改，务必准确填写。在单位信息栏点击【选择】按钮后，将弹出单位选择菜单（如下图），单位类型默认为“学校”，如果是校外机构的辅导教师，注意将单位类型选择为“校外机构”。</p>
          <img src="https://newoa.cyscc.org/profile//castic/2024/06/20/4e7621cf-dbc7-4a11-b27e-46ef738397cc.jpg" alt="">
          
          <div class="tip-box">
            <div class="tip-title">小提示：</div>
            <div class="tip-content"> 为防止恶意注册，教师账号创建完成后，还需本单位的管理员审核，通过之后学生在创建小组时才可看到并选择该教师。 </div>
          </div>
          <img src="https://cyscc.org/dctyhd/img/hd4.adb29954.png" alt="">
          <div class="text-title">
            <span>3.管理学生小组</span>
          </div>
          <p> 在活动页面上，点击导航栏上的【小组管理】，即可管理学生小组。在该页面可以看到以当前账号作为辅导老师的全部学生小组、各小组完成活动的基本情况、查看学生提交的主题报告。 </p>
          <img src="https://cyscc.org/dctyhd/img/fdjs1.c2c4a2cd.png" alt="">
          <div class="text-title">
            <span>4.提交实践报告</span>
          </div>
          <p> 在活动页面上，点击导航栏上的【活动管理】，即进入【科技教育实践报告】的提交页面。在页面中点击【添加报告】，即可上传“科技教育实践报告”。报告文件格式建议为word文档。 </p>
          <img src="https://cyscc.org/dctyhd/img/fdjs2.85d5a59a.png" alt="">
          <p> 教师报告上传后，可下载查看。如果上传文件有误，可删除后再重新提交。务必在实践报告提交截止日期前完成提交 </p>
          <div class="tip-box">
            <div class="tip-title">小提示：</div>
            <div class="tip-content"> 每位老师仅需上传一份实践报告，即便辅导多个小组多个不同主题，也仅需上传一份。报告内容可以是指导活动过程中的总结、心得。教师报告提交后直接参与全国评选，无需经过所在单位推荐。 </div>
          </div>
          <img src="https://cyscc.org/dctyhd/img/fdjs3.ae6c9671.png" alt="">
          <div class="text-title">
            <span>5.授权码</span>
          </div>
          <p> 学生小组在添加辅导教师时，也可通过教师提供的【授权码】进行添加，尤其是学生需要添加非本单位的教师的情况，例如：学生小明所在学校为“光明中学”，但他要添加的教师是科技馆的或其他学校的，此时可通过授权码添加对应老师。【授权码】需要教师登陆活动页面生成，具体生成方法如下： </p>
          <p> 在活动页面上，点击导航栏上的【活动管理】，再点击左侧导航的【授权码】，即进入授权码管理页面。 </p>
          <img src="https://cyscc.org/dctyhd/img/fdjs4.f43f2c31.png" alt="">
          <div class="text-title">
            <span>6.申请管理员(即申请实施单位)</span>
          </div>
          <p> 每个参与单位（学校及校外机构）都可指定一名辅导老师成为本单位的活动管理员。在辅导教师申请成为管理员的同时，对应的单位也将成为实施单位。 </p>
          <div class="tip-box">
            <div class="tip-title">小提示：</div>
            <div class="tip-content"> “实施单位”对应往年的“实施学校”，今年因允许校外机构参与，故改为“实施单位”。 </div>
          </div>
          <p style="color: rgb(82, 147, 211);">申请管理员(即申请实施单位)具体操作如下：</p>
          <p> 在活动页面上，点击导航栏上的【活动管理】，再点击左侧导航的【申请管理员】按钮，即进入申请页面。 </p>
          <img src="https://cyscc.org/dctyhd/img/fdjs5.2d56b1dc.png" alt="">
          <div class="sub-text-title">
            <span>6.1变更管理员</span>
          </div>
          <p> 每个单位（学校或校外机构）只能有一名管理员，如需更换原先的管理员，其他老师可点击导航栏上的【活动管理】，再点击左侧导航的【更换管理员】，然后按照提示填写和上传要求的信息，等待审核通过后即可完成管理员的更换。 </p>
        </div>
        <div class="pop-mask" v-show="isShowPop" @click="closeMask">
          <div class="pop-box" @click="closeMask1">
              <img src="../../assets/images/guideline/js-content.png" alt="" />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowPop: false,
    };
  },
  methods: {
    closeMask() {
      this.isShowPop = false;
    },
    closeMask1(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="less">
.page {
  .pop-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    overflow: auto;
    .pop-box {
      width: 900px;
      margin: 40px auto;
      background-color: #fff;

        img{
            width: 100%;
        }
    }
  }
  width: 890px;
  margin-left: 31px;
  padding-top: 55px;
  padding-bottom: 55px;
  min-width: 890px;
  .main-width-container {
    width: 890px;
    margin: 0 auto;
    .cylct {
      width: 128px;
      height: 32px;
      background: url("../../assets//images/guideline/cylct.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin: 0 auto;
    }
    .text-content {
      margin-top: 48px;
      img {
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        line-height: 32px;
        color: #000;
        margin-bottom: 24px;
        text-indent: 2em;
      }
      .text-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        color: #5293d3;
        position: relative;
        margin-bottom: 24px;
      }
      .sub-text-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #5293d3;
        margin-bottom: 24px;
      }
      .tip-box {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #2084e1;
        background-color: #eaf5ff;
        margin-bottom: 24px;

        .tip-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 32px;
          color: #000000;
          display: inline-block;
        }
        .tip-content {
          font-size: 16px;
          line-height: 32px;
          color: #000000;
          display: contents;
        }
      }
      .text-title::after {
        content: "";
        display: block;
        width: 30px;
        height: 29px;
        background: url("../../assets//images/guideline/Vector.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: middle;
      }
      .text-title span {
        margin-left: 34px;
      }
    }
  }
  .article-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 36px;
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .page {
    .pop-mask {
    .pop-box {
      width: 100%;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
      img{
        width: 100%;
      }

    }
  }
    background: none;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 0;
    margin-left: 0;

    .main-width-container {
      width: 100%;
      margin: 0 auto;
      .text-content {
        margin-top: 0.48rem;

        img {
          width: 100%;
        }
        p {
          line-height: 1.8;
          color: #000;
        }
      }
    }
    .article-title {
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
      line-height: 0.32rem;
      margin-bottom: 0.24rem;
    }
  }
}
</style>
